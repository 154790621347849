import React, { useState } from "react"
import PropTypes from "prop-types"

import Logo from "../images/logo_white.svg"
import Mail from "../images/mail.svg"
import Insta from "../images/instagram.svg"

const Header = () => {
  // -----------------------------initialize state for mobile menu
  const [showMenu, setshowMenu] = useState(false)

  // ----------------------------function to change the state
  const menuActive = () => {
    setshowMenu(!showMenu)
    const body = document.querySelector("body")

    body.classList.toggle("no-scroll")
  }

  const handleMenuClick = () => {
    setshowMenu(false)
    const body = document.querySelector("body")
    body.classList.remove("no-scroll")
  }

  return (
    <header className="mb-12 absolute w-full text-white z-10">
      <div className="container mx-auto px-6 py-8 flex justify-between items-center">
        <a href="/" className="z-30">
          <img
            src={Logo}
            alt="Tailorsnook Logo"
            style={{ height: "90px", width: "37px" }}
          />
        </a>
        {/* mobile menu & hamburger  */}
        <button
          onClick={menuActive}
          className={`hamburger hamburger--spring lg:hidden z-20 ${
            showMenu ? "is-active" : null
          }`}
          type="button"
          aria-label="Menü öffnen"
        >
          <span className="hamburger-box">
            <span className="hamburger-inner"></span>
          </span>
        </button>
        {showMenu && (
          <div className=" absolute top-0 left-0 h-screen w-screen bg-primary flex items-end p-6">
            <div className="mobile-menu mt-7">
              <p className="mb-6 text-lg">
                <a href="/#intro" title="Hello" onClick={handleMenuClick}>
                  Hello
                </a>
              </p>
              <p className="mb-6 text-lg">
                <a
                  href="/#eintagimatelier"
                  title="Ein Tag im Atelier"
                  onClick={handleMenuClick}
                >
                  Ein Tag im Atelier
                </a>
              </p>
              <p className="mb-6 text-lg">
                <a href="/#about" title="Über mich" onClick={handleMenuClick}>
                  About
                </a>
              </p>
              <p className="mb-6 text-lg">
                <a
                  href="/#termin"
                  title="Termin buchen"
                  onClick={handleMenuClick}
                >
                  Timing
                </a>
              </p>
              <p className="mb-6 hover:text-hovering transition duration-200 ease-in-out">
                <a href="/atelier" title="Mein Atelier">
                  Atelier
                </a>
              </p>
              <p className="mb-6 hover:text-hovering transition duration-200 ease-in-out">
                <a href="/roobarb" title="Roobarb Nation">
                  Roobarb Nation
                </a>
              </p>
              <p>
                <a href="/#kontakt" title="Kontakt" onClick={handleMenuClick}>
                  Kontakt
                </a>
              </p>
              <div className="flex justify-start pt-12">
                <a
                  href="https://www.instagram.com/tailors.nook/"
                  target="_blank"
                  title="Tailors Nook auf Instagram"
                  rel="noopener noreferrer"
                  className="mr-8 z-40"
                >
                  <img
                    src={Insta}
                    alt="Instagram Logo"
                    style={{ height: "19px", width: "100%" }}
                  />
                </a>
                <a
                  href="mailto:mail@tailorsnook.de"
                  title="Mail schreiben"
                  className=" z-40"
                >
                  <img
                    src={Mail}
                    alt="Mail Logo"
                    style={{ height: "19px", width: "100%" }}
                  />
                </a>
              </div>
            </div>
          </div>
        )}
        {/* desktop menu */}
        <nav className="hidden lg:flex">
          <p className="mr-9 hover:text-hovering transition duration-200 ease-in-out">
            <a href="/#intro" title="Hello">
              Hello
            </a>
          </p>
          <p className="mr-9 hover:text-hovering transition duration-200 ease-in-out">
            <a href="/#eintagimatelier" title="Ein Tag im Atelier">
              Ein Tag im Atelier
            </a>
          </p>
          <p className="mr-9 hover:text-hovering transition duration-200 ease-in-out">
            <a href="/#about" title="Über mich">
              About
            </a>
          </p>
          <p className="mr-9 hover:text-hovering transition duration-200 ease-in-out">
            <a href="/#termin" title="Termine">
              {" "}
              Timing
            </a>
          </p>
          <p className="mr-9 hover:text-hovering transition duration-200 ease-in-out">
            <a href="/atelier" title="Mein Atelier">
              Atelier
            </a>
          </p>
          <p className="mr-9 hover:text-hovering transition duration-200 ease-in-out">
            <a href="/roobarb" title="Roobarb Nation">
              Roobarb Nation
            </a>
          </p>
          <p className="mr-9 hover:text-hovering transition duration-200 ease-in-out">
            <a href="/#kontakt" title="Kontakt">
              Kontakt
            </a>
          </p>
          <a
            href="https://www.instagram.com/tailors.nook/"
            target="_blank"
            title="Tailors Nook auf Instagram"
            rel="noopener noreferrer"
            className="mr-9 z-40"
          >
            <img
              src={Insta}
              alt="Instagram Logo"
              style={{ height: "19px", width: "100%" }}
            />
          </a>
          <a
            href="mailto:info@tailorsnook.de"
            title="Mail schreiben"
            className=" z-40"
          >
            <img
              src={Mail}
              alt="Mail Logo"
              style={{ height: "19px", width: "100%" }}
            />
          </a>
        </nav>
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
