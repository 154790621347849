/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"

import Header from "./header"
import "../styles/global.css"

import "@fontsource/baskervville"

import Logo from "../images/logo_symbol_white.svg"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
      <main>{children}</main>
      <footer className="py-8 bg-secondary">
        <div className="container mx-auto px-6 grid md:grid-cols-3 gap-6  w-full">
          <div className="flex justify-center md:justify-start">
            <Link to="/impressum" className="text-white mr-8">
              Impressum
            </Link>
            <Link to="/datenschutz" className="text-white">
              Datenschutz
            </Link>
          </div>
          <div className="flex justify-center">
            <img
              src={Logo}
              alt="Tailorsnook Logo"
              title="Tailorsnook Logo"
              style={{ height: "30px" }}
            />
          </div>
          <div className="flex justify-center md:justify-end">
            <a
              href="https://www.instagram.com/tailors.nook/"
              target="_blank"
              title="Tailors Nook auf Instagram"
              rel="noopener noreferrer"
              className="text-white"
            >
              @tailors.nook
            </a>
          </div>
        </div>
      </footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
